import {
  CHART_SYMBOL,
  PRICE_DATA_RECEIVE,
  PRICE_HISTORY_RECEIVE,
} from "./types";

const initSymbols = [
  "EURUSD",
  "GBPUSD",
  "USDJPY",
  "USDCAD",
  "AUDUSD",
  "AUDNZD",
  "AUDCAD",
  "AUDCHF",
  "AUDJPY",
  "BTCUSDT",
  "CADCHF",
  "CHFJPY",
  "EURAUD",
  "EURCAD",
  "EURCHF",
  "EURGBP",
  "EURJPY",
  "EURNZD",
  "GBPCHF",
  "GBPJPY",
  "USDCHF",
  "EURUSD.pro",
  "USDJPY.pro",
  "USDCHF.pro",
  "USOIL",
];

const initialState = {
  quotes: {},
  symbols: initSymbols.map((symbol) => ({ symbol, visible: true })),
  chart: {
    symbol: "",
    interval: 1,
    price: null,
    history: [],
    indArrays: {},
    newIndArrays: {},
  },
};

const pushPrice = ({ price, indSettings, chart }) => {
  const { history, interval, indArrays, newIndArrays } = chart;

  if (history && history.length > 0 && typeof price !== "undefined") {
    if (price.timestamp !== 0) {
      const prevDate = new Date(
        history.length === 0 ? 0 : history[history.length - 1].x * 1000
      );
      const date = new Date(price.timestamp * 1000);
      if (
        (date.getMinutes() !== prevDate.getMinutes() && interval === 1) ||
        (Number.parseInt(date.getMinutes() / 5, 10) !==
          Number.parseInt(prevDate.getMinutes() / 5, 10) &&
          interval === 5) ||
        (Number.parseInt(date.getMinutes() / 15, 10) !==
          Number.parseInt(prevDate.getMinutes() / 15, 10) &&
          interval === 15) ||
        (Number.parseInt(date.getMinutes() / 30, 10) !==
          Number.parseInt(prevDate.getMinutes() / 30, 10) &&
          interval === 30) ||
        (date.getHours() !== prevDate.getHours() && interval === 60) ||
        (Number.parseInt(date.getHours() / 4, 10) !==
          Number.parseInt(prevDate.getHours() / 4, 10) &&
          interval === 240) ||
        (date.getDate() !== prevDate.getDate() && interval === 1440) ||
        (Number.parseInt(date.getDate() / 7, 10) !==
          Number.parseInt(prevDate.getDate() / 7, 10) &&
          interval === 10080) ||
        (date.getMonth() !== prevDate.getMonth() && interval === 40001)
      ) {
        history.push({
          x: price.timestamp,
          open:
            history.length === 0
              ? price.bid
              : history[history.length - 1].close,
          close: price.bid,
          low: price.bid,
          high: price.bid,
        });
      } else {
        if (history.length) {
          history[history.length - 1].close = price.bid;
        }
        if (price.bid > history[history.length - 1].high) {
          history[history.length - 1].high = price.bid;
        }
        if (price.bid < history[history.length - 1].low) {
          history[history.length - 1].low = price.bid;
        }
      }
    }
  }
};

export default (state = initialState, action) => {
  let payload = action.payload;
  switch (action.type) {
    // case LOAD_SYMBOLS: {
    //     const map = {};
    //     payload.forEach(item => {
    //         map[item.symbol] = item.visible;
    //     });
    //     state.symbols.forEach(item => {
    //         if (item.symbol in map) {
    //             item.visible = map[item.symbol];
    //         }
    //     });
    //     return {
    //         ...state,
    //         symLoaded: {},
    //     };
    // }
    // case SAVE_SYMBOLS:
    // return state;
    case PRICE_DATA_RECEIVE: {
      const quotes = state.quotes;
      quotes[payload.symbol] = payload;
      if (payload.symbol === state.chart.symbol) {
        pushPrice({
          chart: state.chart,
          indSettings: state.indSettings,
          price: payload,
        });
        state.chart = { ...state.chart, price: payload };
      }

      return { ...state };
    }
    case CHART_SYMBOL:
      return state.chart.symbol !== payload
        ? {
          ...state,
          chart: {
            ...state.chart,
            symbol: payload.symbol,
            interval: payload.interval,
          },
        }
        : state;
    // case CHART_INTERVAL:
    //     return {
    //         ...state,
    //         chart: {
    //             ...state.chart,
    //             interval: payload,
    //             price: null,
    //             history: [],
    //             indArrays: {},
    //             newIndArrays: {},
    //         },
    //     };
    case PRICE_HISTORY_RECEIVE: {
      state.chart.history = payload;
      // console.log(payload);
      // updateIndicators({ indSettings: state.indSettings, chart: state.chart });
      return {
        ...state,
        chart: {
          ...state.chart,
          price: state.quotes[state.chart.symbol],
        },
      };
    }
    // case PRICE_SYMBOLS_RECEIVE: {
    //     const symbols = [];
    //     initSymbols.forEach(symbol => {
    //         const index = payload.findIndex(item => item.symbol === symbol);
    //         if (index > -1) {
    //             payload.splice(index, 1);
    //             symbols.push({
    //                 symbol,
    //                 visible: true,
    //             });
    //         }
    //     });
    //     payload.forEach(item => {
    //         symbols.push({
    //             symbol: item.symbol,
    //             visible: false,
    //         });
    //     });
    //     symbols[0].visible = 1;
    //     return {
    //         ...state,
    //         symbols,
    //         chart: {
    //             ...state.chart,
    //             symbol: symbols[0].symbol,
    //         },
    //     };
    // }
    default:
      return state;
  }
};
