import { useRef } from "react";
import { Provider } from "react-redux";
import { useLocation } from "react-router-dom";
import "./App.css";
import { ChartComponent } from "./components/Chart";
import { store } from "./redux/store";
import WebSocket from "./websocket";
import { QUOTES_SERVER_IP } from "./constants";

const chartOptions = {
  layout: {
    background: { color: "#222" },
    textColor: "#DDD",
  },
  grid: {
    vertLines: { color: "#444" },
    horzLines: { color: "#444" },
  },
};

function App(props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const symbol = useRef(searchParams?.get("symbol"));
  const chartTag = useRef(
    searchParams?.get("chartTag") ? searchParams?.get("chartTag") : "M1"
  );
  const token = useRef(
    searchParams?.get("T") ? Number.parseInt(searchParams?.get("T")) : 1000
  );
  const sToken = useRef(
    searchParams?.get("ST") ? searchParams?.get("ST") : "Manager123@"
  );

  return (
    <Provider store={store}>
      <WebSocket
      quotesServer={QUOTES_SERVER_IP}
        token={token.current}
        sToken={sToken.current}
        chartTag={chartTag.current}
        symbol={symbol.current}
      />
      <ChartComponent
        {...props}
        chartOptions={chartOptions}
        chartTag={chartTag.current}
        symbol={symbol.current}
      ></ChartComponent>
    </Provider>
  );
}

export default App;
