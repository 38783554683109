import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';

const options = [{ label: 'Moving Average Simple', id: 'movingAvg' }, { label: 'Bollinger', id: 'bollinger' }, {label: 'MACD', id: 'macd'}];

function ConfirmationDialogRaw(props) {
    const { onClose, open, enableIndicatorCallback, disableIndicatorCallback, ...other } = props;

    const indicatorList = React.useRef(new Set(JSON.parse(localStorage.getItem('indicatorList')) || []));
    const removedIndicatorList = React.useRef(new Set());

    const onSelect = (selectedItem) => {
        removedIndicatorList.current.clear();
        indicatorList.current.add(selectedItem);
        localStorage.setItem('indicatorList', JSON.stringify([...indicatorList.current]));
        enableIndicatorCallback(indicatorList.current);
    }

    const onRemove = (removedItem) => {
        removedIndicatorList.current.add(removedItem);
        indicatorList.current.delete(removedItem);
        localStorage.setItem('indicatorList', JSON.stringify([...indicatorList.current]));
        disableIndicatorCallback(removedIndicatorList.current);
    }

    const handleCancel = () => {
        onClose();
    };

    const handleChange = (event) => {
        if (event.target.checked)
            onSelect(event.target.value);
        else
            onRemove(event.target.value);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle>Indicators</DialogTitle>
            <DialogContent dividers>
                <FormControl component="fieldset" variant="standard"
                    aria-label="indicators"
                    name="indicators"
                >
                    {options.map((option) => (
                        <FormControlLabel
                            value={option.id}
                            key={option.id}
                            control={<Checkbox onChange={handleChange} checked={indicatorList.current.has(option.id)} />}
                            label={option.label}
                        />
                    ))}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function IndicatorsDialog(props) {
    const [open, setOpen] = React.useState(false);

    const handleShowIndicatorsList = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton color='primary' onClick={event => { handleShowIndicatorsList(); }}>
                <InsightsOutlinedIcon />
            </IconButton>
            <ConfirmationDialogRaw
                id="indicators-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                {...props}
            />
        </>
    );
}
