import FitScreenOutlinedIcon from '@mui/icons-material/FitScreenOutlined';
import { IconButton, ThemeProvider } from '@mui/material';
import { alpha, createTheme, getContrastRatio } from '@mui/material/styles';
import IndicatorsDialog from './indicatorsDialog';


const violetBase = '#7F00FF';
const violetMain = alpha(violetBase, 0.7);

const theme = createTheme({
  palette: {
    violet: {
      main: violetMain,
      light: alpha(violetBase, 0.5),
      dark: alpha(violetBase, 0.9),
      contrastText: getContrastRatio(violetMain, '#fff') > 4.5 ? '#fff' : '#111',
    },
  },
});

export const ChartLegenedComponent = (props) => {
  const { symbol, price, fitContent, ...other } = props;
  // const [state, setState] = useState({
  //   options: [{ label: 'Moving Average Simple', id: 'movingAvg' }, { label: 'Bollinger', id: 'bollinger' }],
  // });
  // const indicatorList = useRef(new Set());
  // const removedIndicatorList = useRef(new Set());

  // const onSelect = (selectedList, selectedItem) => {
  //   removedIndicatorList.current.clear();
  //   indicatorList.current.add(selectedItem.id);
  //   other.enableIndicatorCallback(indicatorList.current);
  // }

  // const onRemove = (selectedList, removedItem) => {
  //   removedIndicatorList.current.add(removedItem.id);
  //   indicatorList.current.delete(removedItem.id);
  //   other.disableIndicatorCallback(removedIndicatorList.current);
  // }

  const multiselectStyle = {
    multiselectContainer: { // To change css for multiselect (Width,height,etc..)
      // maxHeight: 100,
    },
    searchBox: { // To change search box element look
      // border: 'none',
      fontSize: "10px",
      // minHeight: "50px",
    },
    inputField: { // To change input field position or margin
      margin: "5px",
    },
    chips: { // To change css chips(Selected options)
      // background: '',
    },
    optionListContainer: { // To change css for option container 
      border: " 2px solid",
      background: "transparent"
    },
    optionListContainer: { // To change css for option container 
      // border: " 2px solid",
      background: "transparent"
    },
    option: { // To change css for dropdown options
      // color: 'blue',
    },
    groupHeading: { // To chanage group heading style

    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          color: "white",
          position: "absolute",
          left: "12px",
          top: "12px",
          zIndex: 1000,
          fontSize: "10px",
          fontFamily: "sans-serif",
          lineHeight: "12px",
          fontWeight: 300,
          display: 'flex',
          flexDirection: 'row',
          // alignItems: 'flex-end',
        }}
      >
        <div style={{ padding: 8, }}>
          <div style={{ fontSize: "12px" }}>{symbol}</div>
          <div style={{ fontSize: "10px" }}>{price}</div>
        </div>
        <div
          className="buttons-container"
          style={{}}
        >
          <IconButton color='primary' onClick={event => { fitContent(); }}>
            <FitScreenOutlinedIcon />
          </IconButton>

          <IndicatorsDialog {...other} />
          {/* <Multiselect
            hideSelectedList
            placeholder='Select Indicator'
            options={state.options} // Options to display in the dropdown
            selectedValues={state.selectedValue} // Preselected value to persist in dropdown
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove} // Function will trigger on remove event
            displayValue="label" // Property name to display in the dropdown options
            showCheckbox={true}
          // style={multiselectStyle}
          /> */}
        </div>
      </div>

      {/* <div style={
            {
                color: 'white', position: 'absolute', right: '12px', bottom: '12px',
                zIndex: 100, fontSize: '10px', fontFamily: "sans-serif", lineHeight: "12px",
                fontWeight: 300,
            }
        }
        >
            `<div style={{ fontSize: "12px", }}>
                {symbol}
            </div>
            <div style={{ fontSize: "10px",}}>{price}
            </div>
            <div>
                2024-06-13
            </div>
        </div> */}

    </ThemeProvider>
  );
};
