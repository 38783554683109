import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";
import {
  receivePrice,
  receivePriceHistory,
  setChartSymbolAndChartTag,
} from "./redux/priceAction";
import { QUOTES_SERVER_PORT } from "./constants";
import { getCandleConfig } from "./helpers";


const WebSocket = (props) => {
  const dispatch = useDispatch();
  const { quotesServer, token: login, sToken: password, symbol, chartTag } = props;
  const connectionType = login != 1000 ? 5 : 10;
  const socketUrl = `ws://${quotesServer}:${QUOTES_SERVER_PORT}`;


  const isAuth = useRef(false);
  const intervalID = useRef(null);

  useEffect(() => {
    // console.log(symbol, chartTag);
    dispatch(setChartSymbolAndChartTag({ symbol, chartTag }));
  }, [symbol, chartTag]);

  const onClose = useCallback(() => {
    console.log("websocket closed", quotesServer);
    if (intervalID.current) {
      clearInterval(intervalID.current);
      intervalID.current = null;
    }
  }, [quotesServer]);

  const { readyState, sendJsonMessage, lastJsonMessage } = useWebSocket(
    socketUrl,
    {
      onClose,
      onError: (e) => console.log("websocket error", quotesServer, e),
      shouldReconnect: (e) => true,
      share: true,
      reconnectAttempts: 1000,
    },
    login !== null
  );

  const [socket, setSocket] = useState({
    login: login,
    password: password,
    isLogined: false,
    token: null,
    hasError: !lastJsonMessage?.state,
    errorMsg: !lastJsonMessage?.state ? lastJsonMessage?.err_msg : "",
    message: "",
  });

  const sendHartbeat = useCallback(() => {
    // console.log("send heartbeat");
    sendJsonMessage({ req_code: 0 });
  }, [sendJsonMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    console.log("SERVER IP:", quotesServer, "CONNECTION STATUS:", connectionStatus);
    switch (readyState) {
      case ReadyState.OPEN:
        console.log("websocket opened", quotesServer);
        if (login && password) {
          //   console.log("websocket login request", login);
          sendJsonMessage({
            req_code: 1,
            id: login,
            password: password,
            type: connectionType,
          });
        }
        if (intervalID.current === null) {
          intervalID.current = setInterval(sendHartbeat, 10000);
        }
        break;

      case ReadyState.CLOSED:
        console.log("websocket closed", quotesServer);
        if (intervalID.current) {
          clearInterval(intervalID.current);
          intervalID.current = null;
        }
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState, quotesServer]);

  useEffect(() => {
    if (lastJsonMessage) {
      switch (lastJsonMessage.res_code) {
        case 1:
          // console.log("lastJsonMessage:", lastJsonMessage);
          if (lastJsonMessage.state) {
            // console.log("websocket login response:", login);
            isAuth.current = true;
            setSocket({
              isLogined: true,
              token: lastJsonMessage.hashcode,
              client: lastJsonMessage.name,
              hasError: !!lastJsonMessage.state,
              errorMsg: lastJsonMessage.state ? lastJsonMessage.err_msg : "",
            });
            sendJsonMessage({
              req_code: 5,
              id: login,
              password: password,
              type: connectionType,
            });
          } else {
            isAuth.current = false;
            if (login && password) {
              //   console.log("websocket login request", login);
              sendJsonMessage({
                req_code: 1,
                id: login,
                password: password,
                type: connectionType,
              });
            }
            setSocket({
              login: null,
              password: null,
              isLogined: false,
              token: null,
              hasError: !lastJsonMessage.state,
              errorMsg: !lastJsonMessage.state ? lastJsonMessage.err_msg : "",
            });
          }
          //   console.log(lastJsonMessage);
          break;
        case 2:
          delete lastJsonMessage.res_code;
          dispatch(receivePrice(lastJsonMessage));
          // console.log(lastJsonMessage);
          break;
        case 3:
          // console.log(lastJsonMessage);
          dispatch(receivePriceHistory(lastJsonMessage.array));
          break;
        case 8:
          // console.log(lastJsonMessage);
          //   dispatch(receiveSymbols(lastJsonMessage.array));
          break;
        default:
          // console.log(lastJsonMessage);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  useEffect(() => {
    //     console.log("history.length", history.length);
    //     if(history.length > 0)
    //         return;
    //     const interval = getCandleConfig('M5');
    //     const nowDate = Date.now()
    //     // const newFrom = Number.parseInt((nowDate.getDate() - 1) / 1000 );
    //     const now = Number.parseInt(Date.now() / 1000, 10);
    //     // const from = ;
    //     // console.log("from", from, now);

    //     sendJsonMessage({
    //         symbol: symbol,
    //         req_code: 3,
    //         from: now - numberCandlesPerFrame * 12600 * interval > 0
    //         ? now - numberCandlesPerFrame * 12600 * interval
    //         : 0,
    //         to: -1,
    //         interval: interval,
    if (isAuth.current && chartTag && symbol) {
      const now = Number.parseInt(Date.now() / 1000, 10);
      const { interval, timeFrame: candleNum } = getCandleConfig(chartTag);
      //   console.log(interval, candleNum, symbol, chartTag);
      const tempCandleNum = 3 * candleNum;
      sendJsonMessage({
        symbol: symbol,
        req_code: 3,
        from:
          now - tempCandleNum,
        to: -1,
        interval: interval,
      });
    }
  }, [symbol, chartTag, isAuth.current]);

  useEffect(() => {
    if (sendJsonMessage && socket.message) {
      sendJsonMessage(socket.message);
      console.log("socket message", socket.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket.message]);

  return <></>;
};

export default WebSocket;
