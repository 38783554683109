import {
  MACD_COLORS, MACD_FAST_PERIOD, MACD_SIGNAL_PERIOD, MACD_SLOW_PERIOD, MACD_SOURCE,
} from '../constants';

export function MACD(
  cdlValues,
  macdData,
  params = {
    fastPeriod: MACD_FAST_PERIOD,
    slowPeriod: MACD_SLOW_PERIOD,
    signalPeriod: MACD_SIGNAL_PERIOD,
    applyTo: MACD_SOURCE,
    colors: MACD_COLORS,
  },
) {
  const {
    fastPeriod, slowPeriod, signalPeriod, applyTo, colors,
  } = params;
  const rates_total = cdlValues.length;

  macdData.MACD = [];
  macdData.fastEMA = [];
  macdData.slowEMA = [];
  macdData.signalEMA = [];
  macdData.histogram = [];

  // Check validation
  if (rates_total < slowPeriod) {
    return;
  }

  let last_fast_ema = cdlValues[0][applyTo];
  let last_slow_ema = cdlValues[0][applyTo];
  let last_signal_ema = 0.0;

  for (let index = 0; index < rates_total; index++) {
    if (index === 0) {
      last_fast_ema = cdlValues[0][applyTo];
      last_slow_ema = cdlValues[0][applyTo];
    } else {
      last_fast_ema = getExponentialMA(cdlValues, last_fast_ema, index, fastPeriod, applyTo);
      last_slow_ema = getExponentialMA(cdlValues, last_slow_ema, index, slowPeriod, applyTo);
    }

    const macd = last_fast_ema - last_slow_ema;

    if (index < signalPeriod - 1) {
      macdData.MACD.push({ time: cdlValues[index].time, value: macd });
      macdData.fastEMA.push({ time: cdlValues[index].time, value: last_fast_ema });
      macdData.slowEMA.push({ time: cdlValues[index].time, value: last_slow_ema });
      macdData.signalEMA.push({ time: cdlValues[index].time, value: 0.0 });
      macdData.histogram.push({ time: cdlValues[index].time, value: 0.0 });
    } else {
      last_signal_ema = (macd * (2 / (signalPeriod + 1))) + (last_signal_ema * (1 - (2 / (signalPeriod + 1))));
      const histogramValue = macd - last_signal_ema;

      macdData.MACD.push({ time: cdlValues[index].time, value: macd, color: macd < 0 ? colors.negative : colors.positive });
      macdData.fastEMA.push({ time: cdlValues[index].time, value: last_fast_ema });
      macdData.slowEMA.push({ time: cdlValues[index].time, value: last_slow_ema });
      macdData.signalEMA.push({ time: cdlValues[index].time, value: last_signal_ema });
      macdData.histogram.push({ time: cdlValues[index].time, value: histogramValue });
    }
  }
}

// Function to calculate EMA
function getExponentialMA(values, previousEMA, index, period, applyTo) {
  const k = 2 / (period + 1);
  const price = values[index][applyTo];
  return (price * k) + (previousEMA * (1 - k));
}
